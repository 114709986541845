<template>
  <div class="wrapper" @dblclick="toggleFullScreen">
    <button>全屏</button>
  </div>
</template>

<script>
export default {
  methods: {
    toggleFullScreen() {
      alert("全屏");
      const element = document.documentElement; // 获取整个页面元素
      if (document.fullscreenElement) {
        // 判断是否处于全屏状态
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (element.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      } else {
        alert("else", element.webkitRequestFullscreen === "");
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          /* Firefox */
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          element.webkitRequestFullscreen();
          alert("webkitRequestFullscreen");
        } else if (element.msRequestFullscreen) {
          /* IE/Edge */
          element.msRequestFullscreen();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100vh;
  background: orange;
  position: relative;
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: pink;
  }
}
</style>
